import React from "react"

import Layout from "../components/layout"

export default function privacy() {
  return (
    <Layout>
      <h1 style={{ paddingTop: "80px", textAlign: "center" }}>
        PRIVACY AND POLICIES
      </h1>

      <div style={{ margin: "40px" }}>
        <p>
          <strong>Tripleaim software Privacy Policy</strong>
        </p>
        <p>
          This Privacy Policy describes how your personal information is
          collected, used, and shared when you visit or make a purchase from{" "}
          <a href="http://www.patameds.com">www.patameds.com</a> (the “Site”).
        </p>

        <ol start="1">
          <li>
            <strong>PERSONAL INFORMATION WE COLLECT</strong>
          </li>
        </ol>
        <p>
          When you visit the Site, we automatically collect certain information
          about your device, including information about your web browser, IP
          address, time zone, and some of the cookies that are installed on your
          device. Additionally, as you browse the Site, we collect information
          about the individual web pages or products that you view, what
          websites or search terms referred you to the Site, and information
          about how you interact with the Site. We refer to this automatically
          collected information as “Device Information.”
        </p>

        <ol start="2">
          <li>
            <strong>
              We collect Device Information using the following technologies:
            </strong>
          </li>
        </ol>

        <ol type="a">
          <li>
            “Cookies” are data files that are placed on your device or computer
            and often include an anonymous unique identifier. For more
            information about cookies, and how to disable cookies, visit{" "}
            <a href="http://www.allaboutcookies.org">
              http://www.allaboutcookies.org
            </a>
            .
          </li>
          <li>
            “Log files” track actions occurring on the Site, and collect data
            including your IP address, browser type, Internet service provider,
            referring/exit pages, and date/time stamps.
          </li>
          <li>
            “Web beacons,” “tags,” and “pixels” are electronic files used to
            record information about how you browse the Site.
          </li>
        </ol>

        <p>
          Additionally, when you make a purchase or attempt to make a purchase
          through the Site, we collect certain information from you, including
          your name, billing address, shipping address, payment information
          (including credit card numbers, email address, and phone number. We
          refer to this information as “Order Information.”
        </p>

        <ol start="3">
          <li>
            <strong>
              Other personal information we collect, and process, may include:
            </strong>
          </li>
        </ol>

        <ol type="a">
          <li>
            Basic information – your name, surname (including prefix or title),
            alias, gender, age, or date of birth, as well as your preferred
            language.
          </li>
          <li>
            Contact information – information that enables us to contact you,
            e.g., your personal or business email, mailing address, telephone
            numbers and profile on a social media platform.
          </li>
          <li>
            Professional information and experience – information related to
            your qualifications, areas of expertise, place of practice,
            professional registration number and medical practitioner number.
          </li>
          <li>
            Financial information – your bank account number, credit or debit
            card numbers and other financial and compensation details.
          </li>
          <li>
            Technical and network activity information – information about your
            device and your usage of our websites, apps, and systems, including
            your IP address, device ID, hardware model and version, mobile
            network information, operating system and other online identifiers,
            type of browser, browsing history, search history, access time,
            pages viewed, URLs clicked on, forms submitted, and physical
            location.
          </li>
          <li>
            Product use – data related to your use of our products, your
            interactions with us, your preferred method of communications with
            us, and services you may use.
          </li>
        </ol>
        <p>
          You can choose not to give us personal information when we ask you for
          it. If you decide not to give us your personal information, it may
          restrict our relationship with you. For example, we may not be able to
          provide you with the services that you have requested.
        </p>

        <ol start="4">
          <li>
            <strong>HOW DO WE USE YOUR PERSONAL INFORMATION?</strong>
          </li>
        </ol>
        <p>
          We use the Order Information that we collect generally to fulfill any
          orders placed through the Site (including processing your payment
          information, arranging for shipping, and providing you with invoices
          and/or order confirmations). Additionally, we use this Order
          Information to:
        </p>
        <p>
          Communicate with you; Screen our orders for potential risk or fraud;
          and when in line with the preferences you have shared with us, provide
          you with information or advertising relating to our products or
          services.
        </p>
        <p>
          We use the Device Information that we collect to help us screen for
          potential risk and fraud (in particular, your IP address), and more
          generally to improve and optimize our Site (for example, by generating
          analytics about how our customers browse and interact with the Site,
          and to assess the success of our marketing and advertising campaigns).
        </p>
        <p>
          We share your Personal Information with third parties to help us use
          your Personal Information, as described above. For example, we use
          Google Analytics to help us understand how our customers use the
          Site--you can read more about how Google uses your Personal
          Information here:&nbsp;
          <a href="https://www.google.com/intl/en/policies/privacy/">
            https://www.google.com/intl/en/policies/privacy/
          </a>
          .&nbsp;You can also opt-out of Google Analytics here:&nbsp;
          <a href="https://tools.google.com/dlpage/gaoptout">
            https://tools.google.com/dlpage/gaoptout
          </a>
        </p>
        <p>
          Finally, we may also share your Personal Information to comply with
          applicable laws and regulations, to respond to a subpoena, search
          warrant or other lawful request for information we receive, or to
          otherwise protect our rights.
        </p>

        <ol start="5">
          <li>
            <strong>BEHAVIOURAL ADVERTISING</strong>
          </li>
          <p>
            As described above, we use your Personal Information to provide you
            with targeted advertisements or marketing communications we believe
            may be of interest to you. For more information about how targeted
            advertising works, you can visit the Network Advertising
            Initiative’s (“NAI”) educational page at&nbsp;
            <a href="http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work">
              http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work
            </a>
            .
          </p>

          <p>COMMON LINKS INCLUDE:</p>
          <ol type="a">
            <li>
              <p>
                FACEBOOK -&nbsp;
                <a href="https://www.facebook.com/settings/?tab=ads">
                  https://www.facebook.com/settings/?tab=ads
                </a>
              </p>
            </li>
            <li>
              <p>
                GOOGLE -&nbsp;
                <a href="https://www.google.com/settings/ads/anonymous">
                  https://www.google.com/settings/ads/anonymous
                </a>
              </p>
            </li>
            <li>
              <p>
                BING -&nbsp;
                <a href="https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads">
                  https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads
                </a>
              </p>
            </li>
          </ol>
        </ol>
        <p>
          Additionally, you can opt out of some of these services by visiting
          the Digital Advertising Alliance’s opt-out portal at: &nbsp;
          <a href=" http://optout.aboutads.info/">
            http://optout.aboutads.info/
          </a>
          .
        </p>

        <ol start="6">
          <li>
            <strong>HOW WE PROTECT YOUR PERSONAL INFORMATION</strong>
          </li>
        </ol>
        <p>
          We want to make sure your personal information is not shared with or
          used by those not allowed to see it. We use a variety of security
          measures and technologies to help protect your personal information.
        </p>
        <p>
          We carefully choose service providers to work with, and check they
          have security measures and technologies in place to protect your
          personal information.
        </p>
        <p>
          However, there are no guarantees that a data transmission or storage
          system is 100% secure. If you have reason to believe that your
          interaction with us is no longer secure, please immediately notify us
          using the details at the Contacts.
        </p>

        <ol start="7">
          <li>
            <strong>DO NOT TRACK</strong>
          </li>
        </ol>
        <p>
          Please note that we do not alter our Site’s data collection and use
          practices when we see a Do Not Track signal from your browser.
        </p>

        <ol start="8">
          <li>
            <strong>YOUR RIGHTS</strong>
          </li>
        </ol>
        <p>
          You have the right to access personal information we hold about you
          and to ask that your personal information be corrected, updated, or
          deleted. If you would like to exercise this right, please contact us
          through the contact information below. Additionally, we note that we
          are processing your information to fulfill contracts we might have
          with you (for example if you make an order through the Site), or
          otherwise to pursue our legitimate business interests listed above.
        </p>

        <ol start="9">
          <li>
            <strong>DATA RETENTION</strong>
          </li>
        </ol>
        <p>
          When you place an order through the Site, we will maintain your Order
          Information for our records unless and until you ask us to delete this
          information.
        </p>

        <ol start="10">
          <li>
            <strong>MINORS AND NON-HEALTHCARE PROFESSIONALS</strong>
          </li>
        </ol>
        <p>
          The Site is not intended for individuals under the age of 18 and
          non-healthcare professionals.
        </p>

        <ol start="11">
          <li>
            <strong>CHANGES</strong>
          </li>
        </ol>
        <p>
          We may update this privacy policy from time to time to reflect, for
          example, changes to our practices or for other operational, legal, or
          regulatory reasons.
        </p>

        <ol start="12">
          <li>
            <strong>RESPONSIBILITY REGARDING WEBSITE WE DO NOT OWN</strong>
          </li>
        </ol>
        <p>
          Our websites may contain links to websites or mobile applications we
          do not own or control. This privacy notice does not cover them. Please
          read the privacy notices on those websites and mobile applications if
          you would like to find out how they collect, use, and share your
          personal information.
        </p>

        <ol start="13">
          <li>
            <strong>CONTACT US</strong>
          </li>
        </ol>
        <p>
          For more information about our privacy practices, if you have
          questions, or if you would like to make a complaint, please contact us
          by e-mail at &nbsp;
          <span style={{ color: "blue" }}>support@patameds.com</span>.
        </p>
      </div>
    </Layout>
  )
}
